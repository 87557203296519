* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #e7f5ff;
  display: grid;
  min-height: 100vh;
  place-content: center;
}

.wrapper {
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.5%;
  border-radius: 14px;
}

.video-js {
  display: block;
  border-radius: 7px;
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

.video-js .vjs-chapters-button {
  display: block;
}

.table1 {
  margin-top: 20px;
  width: 90%;
}

.loginfo {
  padding-left: 30px;
}

.srclog {
  font-size: 0.7em;
}
